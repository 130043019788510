<template>
  <div>
    <s-select
      v-model="source"
      class="safe-blue"
      :options="relationOptions"
      @input="$emit('input', source)"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SSelect from '@/components/ui/s-select'

export default {
  name: 'CompanyRelationshipField',

  components: {
    SSelect,
  },

  props: {
    relations: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      source: null,
    }
  },

  computed: {
    ...mapState(['locale']),
    ...mapGetters(['enabledRelations']),

    validLocale() {
      return this.locale === 'pt-BR' ? 'pt' : this.locale
    },

    relationOptions() {
      return this.relations.map((item) => ({
        value: item.key,
        label: item[this.validLocale],
      }))
    },
  },
}
</script>
